import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

import { ApiHelper } from '../../../common/helpers/ApiHelper';
import { Helper } from '../../../common/helpers/Helper';
import { BaseEntityPageSlixta } from '../../../components/BaseEntityPageSlixta';
import { UiHelper } from '../../../common/helpers/UiHelper';
import { AppConfig } from '../../../AppConfig';
import { AppStyles } from '../../../styles/AppTheme';
import { Controller } from '../../../common/Controller';

function getCampaignFields(obj) {
  const transformField = (field, iType) => {
    const outField = JSON.parse(JSON.stringify(field))
    if (field.condition && ((field.condition.value && field.condition.value === iType) || (field.condition.values && field.condition.values.includes(iType)))) {
      delete outField.condition
    }
    return outField
  }
  return {
    AD: obj.fieldDefCampaignAd.steps[0].fields.map(f => transformField(f, 'AD')),
    BROADCAST: obj.fieldDefCampaignBroadcast.steps[0].fields.map(f => transformField(f, 'BROADCAST')),
    TRANSACTIONAL: obj.fieldDefCampaignTransactional.steps[0].fields.map(f => transformField(f, 'TRANSACTIONAL')),
    NOTIFICATION: obj.fieldDefCampaignNotification.steps[0].fields.map(f => transformField(f, 'NOTIFICATION')),
    DRIP: obj.fieldDefCampaignDrip.steps[0].fields.map(f => transformField(f, 'DRIP')),
  }[obj.campaignType] || []
}
function restrictChannels(fieldDef, channels, defaultValue) {
  const channelField = fieldDef.steps[0].fields.find(f => f.key === 'channel')
  channelField.options = channelField.options.filter(o => channels.includes(o.key))
  channelField.defaultValue = defaultValue
}

function findTotalDelayInDays(steps) {
  let totalDelay = 0;
  for (const key in steps) {
    if (steps[key].stepTimeDelayDays) {
      totalDelay += Number(steps[key].stepTimeDelayDays);
    }
  }
  return totalDelay
}

export class CampaignsBase extends BaseEntityPageSlixta {
  constructor(props, options) {
    super(props, options);
    this.ms = AppConfig.CDP_MS.ENDPOINT
    this.campaignType = options.campaignType
    this.pageTitle = options.pageTitle
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.staffUserId = Controller.get().getUserId()
    this.attribsGroupId = 'settingscrmcontactattrs' + this.orgId
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('campaigns') },
      { title: this.pageTitle },
    ]
    this.toFetch = [
      { key: 'campaigns', ms: this.ms, method: 'GET', paths: ['campaigns'], queryParams: { orgId: this.orgId, pb: 'fieldDefCampaignAd,fieldDefCampaignBroadcast,fieldDefCampaignTransactional,fieldDefCampaignNotification,fieldDefCampaignDrip,fieldDefCampaignSchedule', limit: 100, iType: this.campaignType } },
      { key: 'crminboxes', ms: this.ms, method: 'GET', paths: ['crminboxs'], queryParams: { orgId: this.orgId, orgIdUserId: `${this.orgId}-${this.staffUserId}`, includeShared: 'true' } },
      { key: 'crmcontactattrs', ms: this.ms, method: 'GET', paths: ['items', `${this.attribsGroupId}-all`], queryParams: { groupId: this.attribsGroupId } },
    ]
    if (this.campaignType === 'BROADCAST') this.toFetch.push({ key: 'csegments', ms: this.ms, method: 'GET', paths: ['csegments'], queryParams: { orgId: this.orgId, limit: 100 } })
    if (['BROADCAST', 'NOTIFICATION', 'TRANSACTIONAL'].includes(this.campaignType)) {
      this.toFetch.push({ key: 'emailpages', method: 'GET', paths: ['emailpages'], queryParams: { orgId: this.orgId } })
      this.toFetch.push({ key: 'emailpages-PRODUCT', method: 'GET', paths: ['emailpages'], queryParams: { orgId: 'PRODUCT' } })
    }
    this.apiData = {
      orgId: this.orgId
    }
    this.pageKey = this.pageTitle
    this.tableHeadersMap = {
      Index: (current) => current.__index + 1,
      Info: (current) => {
        let template = ''
        switch(current.channel) {
        case 'EMAIL':
          {
            const emailTemplate = this.emailPages.find(p => p.id === current.templateId)
            template = current.iType !== 'BROADCAST' && emailTemplate ? emailTemplate.title : '';
          }
          break;
        }
        return <div>
          <span className={css(Styles.line1)}>{current.title}</span>
          <br />
          {current.iType === 'DRIP' && current.steps && (<>
           <span className={css(Styles.line2)}>{`Steps: ${Object.keys(current.steps).length} | Days: ${findTotalDelayInDays(current.steps)}`}</span>
          <br /></>)}
          {current.sendingWindowDays && UiHelper.renderDaysInWeek(current.sendingWindowDays)}
          { current.sendingWindowStartTime && current.sendingWindowEndTime && (<>
          <span className={css(Styles.line2)}>{`${Helper.formatTimeFrom24to12Hour(current.sendingWindowStartTime)} to ${Helper.formatTimeFrom24to12Hour(current.sendingWindowEndTime)}`}</span>
          <br /></>)}
          {template && <><span className={css(Styles.line2)}>Template: {template}</span><br/></>}
          <span className={css(Styles.line3)}>{Helper.formatTimestamp(current.createdTs)}</span>
        </div>
      },
      Channel: (current) => {
        return <div>
          <span className={css(Styles.line1)}>{current.channel}</span>
        </div>
      },
    }
    switch(this.campaignType) {
    case 'BROADCAST':
      this.tableHeadersMap.Segment = (current) => {
        const segment = this.segments.find(s => s.id === current.segmentId)
        return segment ? segment.title : '';
      }
      break;
    case 'TRANSACTIONAL':
    case 'NOTIFICATION':
      this.tableHeadersMap.Event = (current) => current.transactionalEvent || current.notificationEvent
      break;
    case 'AD':
      delete this.tableHeadersMap.Channel
      this.tableHeadersMap.Media = (current) => current.adMediaType
      this.tableHeadersMap['Budget (USD)'] = (current) => current.adTotalBudget
      this.tableHeadersMap.Targeting = (current) => `${current.adLocation}, ${current.adRadius} miles`
      this.tableHeadersMap.Schedule = (current) => {
        return <>
          {current.scheduleTs && <p className={css(Styles.line2)}>{Helper.formatTimestamp(current.scheduleTs)}</p>}
          {current.scheduleEndTs && <p className={css(Styles.line2)}>{Helper.formatTimestamp(current.scheduleEndTs)}</p>}
        </>
      }
      break;
    case 'DRIP':
      delete this.tableHeadersMap.Channel
      break;
    }
    this.tableHeadersMap.Status = (current) => current.state
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.headerActions = [
      {
        label: 'Add new',
        onClick: () => this.openModalForAdd(),
      },
      {
        label: 'Refresh',
        tooltip: 'Refresh',
        icon: 'refresh',
        type: 'icon-label',
        onClick: () => this.fetchItems(),
      }
    ]
    this.uploadMs = AppConfig.CDP_MS.ENDPOINT
  }

  onFetchSuccess(results) {
    this.campaigns = this.getResult(results, 'campaigns')
    this.csegments = this.getResult(results, 'csegments')
    this.emailpagesOrg = this.getResult(results, 'emailpages')
    this.emailpagesPRODUCT = this.getResult(results, 'emailpages-PRODUCT')
    this.sharedCrmInboxes = this.getResult(results, 'crminboxes').items.filter(i => i.sharedInbox === 'YES')
    this.crmContactAttrs = this.getResult(results, 'crmcontactattrs')

    this.fieldDefCampaignAd = this.campaigns.fieldDefCampaignAd
    this.fieldDefCampaignBroadcast = this.campaigns.fieldDefCampaignBroadcast
    this.fieldDefCampaignTransactional = this.campaigns.fieldDefCampaignTransactional
    this.fieldDefCampaignNotification = this.campaigns.fieldDefCampaignNotification
    this.fieldDefCampaignDrip = this.campaigns.fieldDefCampaignDrip
    this.fieldDefCampaignSchedule = this.campaigns.fieldDefCampaignSchedule

    restrictChannels(this.fieldDefCampaignAd, ['OOH'], 'OOH')
    restrictChannels(this.fieldDefCampaignBroadcast, ['EMAIL'], 'EMAIL')
    restrictChannels(this.fieldDefCampaignTransactional, ['EMAIL'], 'EMAIL')
    restrictChannels(this.fieldDefCampaignNotification, ['EMAIL'], 'EMAIL')

    this.fieldDefCampaignBroadcast = UiHelper.removeFields(this.fieldDefCampaignBroadcast, ['templateId'])
    this.fieldDefCampaignBroadcast = UiHelper.reflowFields(this.fieldDefCampaignBroadcast)

    if (this.campaignType === 'AD') {
      this.fieldDefCampaignSchedule.disableAlwaysFormFields = ['scheduleType']
    } else {
      UiHelper.fieldDefDeleteField(this.fieldDefCampaignSchedule, 'scheduleEndTs')
    }

    if (this.csegments) {
      this.segments = this.csegments.items.filter(i => i.state === 'LIVE')
      UiHelper.populateOptions(this.fieldDefCampaignBroadcast, {items: this.segments}, 'segmentId')
    } else {
      this.segments = []
    }

    if (this.emailpagesOrg && this.emailpagesPRODUCT) {
      this.emailPages = [
        ...this.emailpagesOrg.items,
        ...this.emailpagesPRODUCT.items.filter(i => i.state === 'LIVE').map(i => ({...i, title: `[${i.title}]`})),
      ]
      UiHelper.populateOptions(this.fieldDefCampaignBroadcast, {items: this.emailPages}, 'templateId')
      UiHelper.populateOptions(this.fieldDefCampaignTransactional, {items: this.emailPages}, 'templateId')
      UiHelper.populateOptions(this.fieldDefCampaignNotification, {items: this.emailPages}, 'templateId')
    } else {
      this.emailPages = []
    }

    UiHelper.populateOptions(this.fieldDefCampaignDrip, {items: this.sharedCrmInboxes.map(i => ({id: i.id, title: i.email}))}, 'triggerInboxId')
    UiHelper.populateOptions(this.fieldDefCampaignDrip, {items: Helper.extractAttrs(this.crmContactAttrs, 'tags').map(t => ({id: t, title: t}))}, 'triggerOnTags')

    this.setState({
      items: this.campaigns.items.sort((a, b) => b.createdTs - a.createdTs)
    })
  }

  openModalForAdd() {
    super.openModalForAdd({steps: [{ fields: getCampaignFields(this) }]});
  }
  onAdd() {
    this.apiData = {
      orgId: this.orgId,
      iType: this.campaignType,
    }
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns'], queryParams: {} }),
    });
  }

  openModalForEdit(current) {
    const fields = getCampaignFields(this)
    fields.find(f => f.key === 'title')
    const disableAlwaysFormFields = current.state === 'DRAFT' ? [] : ['channel', 'templateId', 'transactionalEvent', 'notificationEvent', 'segmentId']
    super.openModalForEdit(current, {steps: [{ fields }], disableAlwaysFormFields});
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath2({ ms: this.ms, paths: ['campaigns', current.id], queryParams: {} }),
    });
  }

  // onDelete(current) {
  //   this.setState({
  //     apiMethod: 'DELETE',
  //     apiEndPoint: ApiHelper.makeUrlPath(['campaigns', current.id], {}),
  //   });
  // }

  async updateCampaignState(current, action) {
    if (['AD', 'BROADCAST'].includes(current.iType) && action === 'start') {
      this.apiData = {
        updateAction: action,
        orgId: this.orgId
      }
      super.openModalForEdit(current, this.fieldDefCampaignSchedule)
    } else {
      this.apiData = {
        updateAction: action,
        orgId: this.orgId
      }
      const fields = [{
        key: 'updateAction',
        label: 'Update action',
        type: 'edit_text',
        hint: 'Update action',
        // eslint-disable-next-line camelcase
        v_required: { value: true, err: 'Provide update action' },
        placement: 'left',
        value: action,
        disabled: true,
      }]
      current.updateAction = action
      super.openModalForEdit(current, {steps: [{ fields }]});
    }
  }

  onActions = (current) => {
    const actions = []
    if (current.iType === 'BROADCAST') {
      actions.push(
        <Link to={this.baseRoute() + '/campaigns/' + current.id} key={current.id}>
          <Tooltip title='View Campaign' placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>arrow_forward_ios</i>
            </span>
          </Tooltip>
        </Link>
      )
    } else {
      actions.push(UiHelper.buttonEdit(this, current))
      if(['DRAFT', 'PAUSED'].includes(current.state)) {
        actions.push(
          <Tooltip title='Start' placement='top-start' key={current.id + '-start'}>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined' onClick={() => {
                if(current.state === 'DRAFT') {
                  this.updateCampaignState(current, 'start')
                } else if (current.state === 'PAUSED') {
                  this.updateCampaignState(current, 'resume')
                }
              }}>
              send
              </i>
            </span>
          </Tooltip>
        )
      }
      if(['ACTIVE', 'LIVE', 'SCHEDULED', 'REVIEW'].includes(current.state)) {
        actions.push(
          <Tooltip title='Pause' placement='top-start' key={current.id + '-pause'}>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined' onClick={() => {
                this.updateCampaignState(current, 'pause')
              }}>
              pause
              </i>
            </span>
          </Tooltip>
        )
      }
      if(['COMPLETED', 'PAUSED', 'LIVE', 'ACTIVE'].includes(current.state) && ['AD', 'BROADCAST'].includes(current.iType)) {
        actions.push(
          <Link to={this.baseRoute() + '/campaigns/' + current.id + '/results'} key={current.id + '-results'}>
            <Tooltip title='Results' placement='top-start'>
              <span className={css(AppStyles.entityTableLinkIcon)}>
                <i className='material-icons-outlined'>trending_up</i>
              </span>
            </Tooltip>
          </Link>
        )
      }
      if (current.iType === 'DRIP') {
        actions.push(
          <Link to={this.baseRoute() + '/campaigns/sequences/' + current.id + '/steps'} key={current.id + '-steps'}>
            <Tooltip title='Steps' placement='top-start'>
              <span className={css(AppStyles.entityTableLinkIcon)}>
                <i className='material-icons-outlined'>list</i>
              </span>
            </Tooltip>
          </Link>
        )
        actions.push(
          <Link to={this.baseRoute() + '/campaigns/sequences/' + current.id + '/enrolments'} key={current.id + '-enrolments'}>
            <Tooltip title='Enrolments' placement='top-start'>
              <span className={css(AppStyles.entityTableLinkIcon)}>
                <i className='material-icons-outlined'>group</i>
              </span>
            </Tooltip>
          </Link>
        )
        if (current.state === 'LIVE') {
          actions.push(<Tooltip title={'Resync'} placement='top-start' key={current.id + 'resync'}>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined' onClick={() => this.openModalForResync(current)}>refresh</i>
            </span>
          </Tooltip>)
        }
      }
    }
    return actions
  }

  onMenuItems = (current) => {
    const actions = []
    const menuItems = [
      {
        current: current,
        key: current.id + 'create-copy',
        action: () => {
          UiHelper.openModalForCreateCopy.call(this, current, 'CAMPAIGN')
        },
        title: 'Create a copy',
        icon:'copy',
      },
    ]
    menuItems.map(item => {
      actions.push(UiHelper.actionButton(item))
    })
    return actions
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) { return '' }
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  updateLoaderUi(isLoading) {
    this.setState({
      fetchState: isLoading ? ApiHelper.State.LOADING : ApiHelper.State.READY
    });
  }
}

const Styles = StyleSheet.create({
  line1: {
    fontSize: 16
  },
  line2: {
    fontSize: 12,
    color: 'gray'
  },
  line3: {
    fontSize: 12,
    color: '#AAAAAA'
  },
})