import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppStyles } from '../../styles/AppTheme';
import { CustomCbDefsReadStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { CbSelection } from '../../common/components/CbSelection';
import { Headerbar } from '../../common/components/Headerbar';
import { AppUiHelper } from '../../helpers/AppUiHelper';
import { BaseEntityPageSlixta } from '../../components/BaseEntityPageSlixta';
import { AppHelper } from '../../helpers/AppHelper';
import { SearchBar } from '../../common/components/SearchBar';

export class PageCbs extends BaseEntityPageSlixta {

  constructor(props) {
    super(props, { assetPicker: true });
    this.pageTitle = Helper.getString('pagecbs')
    this.pageId = this.props.match.params.id
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.toFetch = [
      { method: 'GET', paths: ['pages', this.pageId], queryParams: { pb: 'fieldDefPageBlock' } },
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: 'PRODUCT', pb: 'fieldDef' } },
      { method: 'GET', paths: ['cbdefs'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['items', 'cborg' + this.orgId + '-all'], queryParams: { groupId: 'cborg' + this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['cotypes'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['forms'], queryParams: { orgId: this.orgId, pb: 'fieldDef' } },
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['items'], { groupId: 'cbdeftagPRODUCT', pb: 'fieldDef' }) },
      { method: 'GET', paths: ['pages'], queryParams: { orgId: this.orgId,
        pb: 'fieldDef,fieldDefPageFulfilment,fieldDefSEO,fieldDefPageAdvanced' } },
    ]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      Section: (current) => {
        return (
          <div className={css(Styles.sectionContainer)}>
            <div className={css(Styles.imageContainer)}>
              {current._image ?
                <img src={current._image}
                  className={css(Styles.sectionImage)} /> :
                <i className='material-icons-outlined' style={{ fontSize: 40, color: 'lightgray' }}>image</i>
              }
            </div>
            <div className={css(Styles.sectionInfoContainer)}>
              <span className={css(Styles.spanValue)}>{current.title || '(no section title)'}</span>
              <span className={css(Styles.spanValue, Styles.line2)}>{current._widgetName}</span>
              {/* {current._pageCbId && (
                <span className={css(Styles.spanValue, Styles.line2)}>
                  {current._pageCbId}
                </span>
              )} */}
            </div>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.apiData = {
      orgId: this.orgId
    }
    this.entityAttrName = 'blocks'
  }

  onFetchSuccess(results) {
    this.page = results[0]
    this.coTypes = results[4]
    this.forms = results[5]
    this.fieldDefSEO = results[7].fieldDefSEO
    this.fieldDefPageAdvanced = results[7].fieldDefPageAdvanced
    this.fieldDefCoTypePage = {
      steps: [
        {fields: results[7].fieldDef.steps[0].fields.filter(i =>
          Helper.inArray(['title', 'slug', 'state', 'includeInSitemap', 'coTypeId'], i.key))}
      ]
    }
    this.editPageFieldDef = {
      steps: [
        {fields: results[7].fieldDef.steps[0].fields.filter(i => !Helper.inArray(['coTypeId'], i.key)) }
      ]
    }
    this.conf = {
      previewPath: '/' + this.page.slug
    }
    this.page.fieldDefPageBlock.steps[0].fields =
      this.page.fieldDefPageBlock.steps[0].fields.filter(i => i.key !== 'cbDataType') //legacy
    this.fieldDef = this.formDefinition = this.page.fieldDefPageBlock
    this.cbSource = this.fieldDef.steps[0].fields.find((o) => o.key === 'cbSource')
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
      { title: Helper.getString('pages'), to: this.baseRoute() + '/pages' },
      { title: this.page.title }
    ]
    if(this.page && this.page.title) {
      this.pageTitle = this.page.title
    }
    this.cbGlobal = results[1]
    console.log('this.cbGlobal:', this.cbGlobal)
    this.cbGlobalById = Helper.itemsToMap(this.cbGlobal.items)
    this.cbOrg = results[2]
    console.log('this.cbOrg:', this.cbOrg)
    this.cbOrgById = Helper.itemsToMap(this.cbOrg.items)

    this.cbSite = {
      items: AppHelper.modifyCbSiteData(Helper.dictToArray(results[3].items),this.cbGlobal,this.cbOrgById)
    }
    console.log('this.cbSite:', this.cbSite)
    this.cbSiteById = Helper.itemsToMap(this.cbSite.items)

    if (this.pageId) {
      this.breadCrumbs = [{ title: Helper.getString('backToPages'), to: this.baseRoute() + '/pages', type: 'button', icon: 'arrow_back_ios' }]
    }

    UiHelper.populateOptions(this.formDefinition, this.cbGlobal, 'cbGlobalId')
    UiHelper.populateOptions(this.formDefinition, this.cbOrg, 'cbTenantId')
    UiHelper.populateOptions(this.formDefinition, this.cbSite, 'cbSiteBlockId')
    const widgets = Helper.sortDictArray(Helper.dictToArray(this.page[this.entityAttrName]))
    widgets.forEach(i => {
      if(i.cbGlobalId && this.cbGlobalById[i.cbGlobalId]) {
        i._widgetName = this.cbGlobalById[i.cbGlobalId].title
        i._singleton = this.cbGlobalById[i.cbGlobalId].singleton
        i._cbScopeId = 'p' + this.orgId + '-' + i.id + '-' + i.cbGlobalId
        i._image = this.cbGlobalById[i.cbGlobalId].image || null;
        i._pageCbId = AppHelper.pageCbId(i.id)
      } else if(i.cbTenantId && this.cbOrgById[i.cbTenantId]) {
        i._widgetName = this.cbOrgById[i.cbTenantId].title
        i._singleton = this.cbOrgById[i.cbTenantId].singleton
        i._cbScopeId = 'p' + this.orgId + '-' + i.id + '-' + i.cbTenantId
        i._pageCbId = AppHelper.pageCbId(i.id)
      } else if(i.cbSiteBlockId && this.cbSiteById[i.cbSiteBlockId]) {
        i._widgetName = this.cbSiteById[i.cbSiteBlockId].title
        i._cbScopeId = this.cbSiteById[i.cbSiteBlockId].reusableTraverseId
        i._singleton = this.cbSiteById[i.cbSiteBlockId].singleton
        i._image = this.cbSiteById[i.cbSiteBlockId].image || null
        i._pageCbId = AppHelper.pageCbId(i.id)
      }
    })
    console.log('widgets:', widgets)
    console.log('widgets:names:', widgets.map(i => i._widgetName))
    this.setState({
      items: widgets,
      orgItemsList:widgets,
      masterTagsList: (results[6] && results[6].items) || [],
    })
  }

  onAdd() {
    this.formDefinition.disableAlwaysFormFields = ['cbSource', 'cbTenantId', 'cbGlobalId', 'cbSiteBlockId']

    if (this.state.selectedBlockSource === 'GLOBAL') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').value = this.state.selectedBlockType
    } else if (this.state.selectedBlockSource === 'TENANT') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').value = this.state.selectedBlockType
    } else if (this.state.selectedBlockSource === 'SITE') {
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
      this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').value = this.state.selectedBlockType
    }

    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').value = this.state.selectedBlockSource
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').defaultValue = this.state.selectedBlockSource

    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'add', entityAttrName: this.entityAttrName },
      apiEndPoint: ApiHelper.makeUrlPath(['pages', this.pageId], {}),
    });
  }

  onEdit(current) {
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSource').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbGlobalId').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbTenantId').disabled = true
    this.formDefinition.steps[0].fields.find(i => i.key === 'cbSiteBlockId').disabled = true
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'edit', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['pages', this.pageId], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'PUT',
      apiData: { entityAction: 'del', entityAttrName: this.entityAttrName, id: current.id },
      apiEndPoint: ApiHelper.makeUrlPath(['pages', this.pageId], {}),
    });
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current, 'Edit section'))
    actions.push(UiHelper.buttonDelete(this, current))
    if (this.page.createdTs <= 1651835481669 && current.cbDataType !== 'SHARED') { // legacy
      actions.push(
        <Link to={this.baseRoute() + '/cbdats/' + this.pageId + '-' + current.id + '-' + (current.cbGlobalId || current.cbTenantId)} key={current.id + '-cbdats'}>
          <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>dataset</i>
            </span>
          </Tooltip>
        </Link>
      )
    } else if (current.cbSource === 'GLOBAL' || current.cbSource === 'TENANT') {
      // if((current._singleton  === 'YES') || this.entityAttrName === 'cotypeblocks') {
      // // Dont go to another page for convenience of only one item to be edited
      //   actions.push(
      //     <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
      //       <span className={css(AppStyles.entityTableLinkIcon)}>
      //         <i className='material-icons-outlined'
      //           onClick={() => AppUiHelper.handleSingletonWidgetData(this, current)}>dataset</i>
      //       </span>
      //     </Tooltip>
      //   )
      // } else {
      //   actions.push(
      //     <Link to={this.baseRoute() + '/cbdats2/' + current._cbScopeId + '?pageId=' + this.pageId} key={current.id + '-cbdats'}>
      //       <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
      //         <span className={css(AppStyles.entityTableLinkIcon)}>
      //           <i className='material-icons-outlined'>dataset</i>
      //         </span>
      //       </Tooltip>
      //     </Link>
      //   )
      // }
      actions.push(
        <Link to={this.baseRoute() + '/cbdats2/' + current._cbScopeId + '?pageId=' + this.pageId} key={current.id + '-cbdats'}>
          <Tooltip title={Helper.getString('cbdats')} placement='top-start'>
            <span className={css(AppStyles.entityTableLinkIcon)}>
              <i className='material-icons-outlined'>dataset</i>
            </span>
          </Tooltip>
        </Link>
      )

    } else if(current.cbSource === 'SITE') {
      // if(current._singleton === 'YES') {
      //   actions.push (
      //     <Tooltip title={Helper.getString('reusableData')} placement='top-start'>
      //       <span className={css(AppStyles.entityTableLinkIcon)}>
      //         <i className='material-icons-outlined'
      //           onClick={() => AppUiHelper.handleSingletonWidgetData(this, current)}>dataset_linked</i>
      //       </span>
      //     </Tooltip>
      //   )
      // } else {
      //   let qParams = '?pageId=' + this.pageId
      //   if(this.entityAttrName === 'cotypeblocks') qParams = qParams + '&pageType=cotypeblocks'
      //   actions.push (
      //     <Tooltip title={Helper.getString('reusableData')} placement='top-start'>
      //       <span className={css(AppStyles.entityTableLinkIcon)}>
      //         <Link to={this.baseRoute() + '/cbdats2/' + current._cbScopeId + qParams} className={css(Styles.linkIcon)}>
      //           <i className='material-icons-outlined'>dataset_linked</i>
      //         </Link>
      //       </span>
      //     </Tooltip>
      //   )
      // }
      let qParams = '?pageId=' + this.pageId
      if(this.entityAttrName === 'cotypeblocks') qParams = qParams + '&pageType=cotypeblocks'
      actions.push (
        <Tooltip title={Helper.getString('reusableData')} placement='top-start'>
          <span className={css(AppStyles.entityTableLinkIcon)}>
            <Link to={this.baseRoute() + '/cbdats2/' + current._cbScopeId + qParams} className={css(Styles.linkIcon)}>
              <i className='material-icons-outlined'>dataset_linked</i>
            </Link>
          </span>
        </Tooltip>
      )
    }
    return actions
  }

  onSettingsEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['pages', current.id], {}),
    });
  }

  openModalPageEdit(current) {
    console.log('edit:', current);
    this.formDefinition = this.editPageFieldDef
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onSettingsEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  openModalForCoTypePageEdit(current) {
    console.log('edit:', current);
    this.formDefinition = this.fieldDefCoTypePage
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onSettingsEdit(current);
    this.setState({
      modalOpen: true
    });

  }

  openModalForAdvanced(current) {
    console.log('openModalForAdvanced:', current);
    this.formDefinition = this.fieldDefPageAdvanced
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onSettingsEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  openModalForSEO(current) {
    console.log('openModalForSEO:', current);
    this.formDefinition = this.fieldDefSEO
    this.formDefinition.formType = 'edit'
    Helper.populateFormDefinitionValues(this.formDefinition, current)
    this.onSettingsEdit(current);
    this.setState({
      modalOpen: true
    });
  }

  renderHeaderbar() {
    const title = this.pageTitle ? this.pageTitle : null
    const pageKey = this.pageKey ? this.pageKey : null
    const actions = [
      {
        label: 'Add widget',
        onClick: () => this.setState({ blockSelectionModal: true }),
      },
    ];

    if(this.entityAttrName !== 'cotypeblocks') {
      actions.push({
        tooltip: Helper.getString('editPage'),
        icon: 'edit',
        type: 'icon-label',
        onClick: this.page.coTypeId
          ? () => this.openModalForCoTypePageEdit(this.page)
          : () => this.openModalPageEdit(this.page),
        label: 'Edit',
      },
      {
        tooltip: Helper.getString('advancedPageSettings'),
        icon: 'settings_suggest',
        type: 'icon-label',
        onClick: () => this.openModalForAdvanced(this.page),
        label: 'Advanced'
      },
      {
        tooltip: Helper.getString('pageSeo'),
        icon: 'content_paste_search',
        type: 'icon-label',
        onClick: () => this.openModalForSEO(this.page),
        label: 'SEO'
      },)
    }

    if (this.export) {
      actions.push({
        label: 'Export',
        icon: 'file_download',
        onClick: () => this.onExport()
      })
    }
    if (this.conf && this.conf.previewPath) {
      actions.push({
        tooltip: Helper.getString('previewPage'),
        icon: 'desktop_windows',
        type: 'icon-label',
        label:'Preview',
        onClick: () => this.openNewWindow()
      })
    }
    return (
      <Headerbar
        pageKey={pageKey}
        title={title}
        actions={actions}
        actionComponents={this.state.headerActionComponents}
        breadCrumbs={this.breadCrumbs}
      />
    )
  }

  doActionNext = (selectedBlockSource, selectedBlockType) => {
    this.setState({
      blockSelectionModal: false,
      selectedBlockSource: selectedBlockSource,
      selectedBlockType: selectedBlockType
    }, () => this.openModalForAdd())
  }

  renderBelowTable() {
    return this.state.blockSelectionModal ? this.renderBlockSelectionModal() : ''
  }
  renderAboveTable() {
    return <SearchBar
      hint={Helper.getString('widgetSearchHint')}
      onSearch={this.onSearch.bind(this)}
      onClearSearch={this.clearSearch.bind(this)} // remove this if reset filters is not needed
      searchText={this.state.searchText}
      searchButtonLabel='Filter'
      clearSearchLabel='Clear Search'
      autoRefresh={true}
      debounceDuration={1}
      autoFocus={true}
    >
    </SearchBar>
  }

  clearSearch() {
    this.setState({
      items:this.state.orgItemsList || []
    })
  }

  onSearch(searchText = '', searchFilters = {}, filterOptions = []) {
    // console.log(this.state.orgItemsList,'ALL_WIDGETS_LIST');

    if(!searchText) {
      return this.setState({
        items:this.state.orgItemsList
      })
    }
    const search = searchText.toLowerCase()
    const widgetslist = [...this.state.orgItemsList]

    const filteredList = widgetslist.filter((widget) => {
      return (
        widget.title && widget.title.toLowerCase().includes(search) ||
        widget._widgetName && widget._widgetName.toLowerCase().includes(search)
      )
    });

    this.setState({
      items:filteredList
    })
  }


  renderBlockSelectionModal() {
    return (
      <CbSelection
        title={Helper.getString('blockSelection')}
        isOpen={this.state.blockSelectionModal}
        onClosed={() => this.setState({ blockSelectionModal: false})}
        cbOrg={this.cbOrg}
        selectedBlock={'SITE'}
        cbGlobal={this.cbGlobal}
        cbSource={this.cbSource}
        cbSite={this.cbSite}
        doActionNext={this.doActionNext}
        page={this.page}
        masterTagsList={this.state.masterTagsList}
      />
    );
  }

}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDefsReadStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})
