import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { BaseEntityPage } from '../../common/components/BaseEntityPage';
import { UiHelper } from '../../common/helpers/UiHelper';
import { CustomCbDatsStyles } from '../../styles/CustomStyles';
import { AppConfig } from '../../AppConfig';
import { AppHelper } from '../../helpers/AppHelper';

export class CbDats extends BaseEntityPage {

  constructor(props) {
    super(props);
    this.pageTitle = Helper.getString('cbdats')
    this.orgId = this.props.match.params.orgId || 'PRODUCT'
    this.cbdId = this.props.match.params.id
    const splits = this.cbdId.split('-')
    if (splits.length === 4) {
      this.pageId = splits[0]
      this.pageCbId = splits[1]
      this.cbId = splits[2] + '-' + splits[3]
      this.cbdId = splits[1] + '-' + splits[2] + '-' + splits[3]
    } else if (splits.length === 2) {
      this.cbId = this.cbdId
    }
    this.toFetch = [
      { method: 'GET', paths: ['cbdats'], queryParams: { cbId: this.cbdId, pb: 'fieldDef' } },
      { method: 'GET', paths: ['cbdefs', this.cbId], queryParams: {} },
    ]
    if (this.pageId) {
      this.toFetch.push({ method: 'GET', paths: ['pages', this.pageId], queryParams: {} })
    }
    this.apiData = {
      cbId: this.cbdId
    }
  }

  onFetchSuccess(results) {
    this.pageTitle = 'Data for "' + results[1].title + '"'
    this.breadCrumbs = [
      { title: Helper.getString('home'), to: this.baseRoute() },
    ]
    if (this.pageId) {
      this.breadCrumbs.push({ title: 'Pages', to: this.baseRoute() + '/pages' })
      this.breadCrumbs.push({ title: Helper.ellipsis(results[2].title, 20), to: this.baseRoute() + '/pages/' + this.pageId + '/blocks' })
      this.breadCrumbs.push({ title: Helper.getString('blocks') })
    } else {
      this.breadCrumbs.push({ title: this.pageTitle })
    }

    this.formDefinition = results[0].fieldDef
    const fieldOne = this.formDefinition.steps[0].fields[0]
    this.tableHeadersMap = {
      Order: (current) => { return current.order },
      Data: (current) => {
        return (
          <div>
            <span className={css(Styles.spanValue)}>{Helper.ellipsis(AppHelper.infoTextHelper(current[fieldOne.key]))}</span>
          </div>
        )
      },
      Status: (current) => {
        return UiHelper.itemStatusContainer(current)
      }
    }
    this.tableHeaders = Object.keys(this.tableHeadersMap)
    this.setState({
      items: Helper.sortDictArray(results[0].items)
    })
  }

  onTableValue(current, index) {
    if (!this.tableHeaders[index]) return ''
    return this.tableHeadersMap[this.tableHeaders[index]](current)
  }

  onAdd() {
    this.setState({
      apiMethod: 'POST',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats'], {}),
    });
  }

  onEdit(current) {
    this.setState({
      apiMethod: 'PUT',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats', current.id], {}),
    });
  }

  onDelete(current) {
    this.setState({
      apiMethod: 'DELETE',
      apiEndPoint: ApiHelper.makeUrlPath(['cbdats', current.id], {}),
    });
  }

  onActions = (current) => {
    const actions = []
    actions.push(UiHelper.buttonEdit(this, current))
    actions.push(UiHelper.buttonDelete(this, current))
    return actions
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomCbDatsStyles : StyleSheet.create({
  spanValue: {
    fontSize: 12
  }
})