import React, { Component, Fragment } from 'react';
import { Helper } from '../../helpers/Helper';
import { StyleSheet, css } from 'aphrodite';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { AppTheme } from '../../../styles/AppTheme';
import { CustomPreviewDialogStyles } from '../../../styles/CustomStyles';
import { AppConfig } from '../../../AppConfig';
import { ApiHelper } from '../../../common/helpers/ApiHelper';
import './macbookpro.css'
import './iphonex.css'

const queryString = require('query-string');

const AppThemeSwitch = withStyles({
  switchBase: {
    color: AppTheme.primaryColor,
    '&$checked': {
      color: AppTheme.primaryColor,
    },
    '&$checked + $track': {
      backgroundColor: '#000000',
    },
    '& + $track': {
      backgroundColor: '#000000',
    },
  },
  checked: {},
  track: {},
})(Switch);

export class ItemPreview extends Component {
  constructor(props) {
    super(props)
    this.title = this.props.title ? this.props.title : Helper.getString('preview')
    this.qParams = queryString.parse(this.props.location.search);
    this.orgId = this.props.orgId ? this.props.orgId : this.props.match.params.orgId
    const ppStr = localStorage.getItem('productpreview')
    const ppData = ppStr ? JSON.parse(ppStr) : {}
    const widHash = (this.props.location && this.props.location.hash) || ''

    this.state = {
      iframeKey: 0,
      iframeUrl: this.qParams.ppath ? 'https://preview-' + this.orgId + '.slixapp.com' + this.qParams.ppath + (widHash ? widHash : '') : '',
      templateId: this.qParams.templateId,
      template: {},
      //mobileView: window.innerWidth > 768 ? false : true,
      mobileView: ppData.mobileView ? true : false,
      dialogOpen: this.props.orgId ? this.props.isOpen : true
    }
  }

  componentDidMount() {
    localStorage.removeItem('productpreview')
    this.reloadTemplate()
  }

  onCloseModal() {
    window.close()
    //this.props.onClosed()
  }

  reloadTemplate() {
    if (this.state.templateId) {
      ApiHelper.callAwait({
        method: 'GET',
        ms: AppConfig.CDP_MS.ENDPOINT,
        paths: ['miscs', 'get-rendered-email-template'],
        queryParams: {orgId: this.orgId, templateId: this.state.templateId}
      }).then(result => {
        this.setState({
          template: result.body,
        })
      })
    }
  }

  iframeRefresh() {
    this.reloadTemplate()
    this.setState({ iframeKey: this.state.iframeKey + 1 });
  }

  handleChange = (event) => {
    this.setState({ mobileView: event.target.checked });
    localStorage.setItem('productpreview', JSON.stringify({ mobileView: event.target.checked }))
  };

  render() {
    return (
      <Dialog
        open={this.state.dialogOpen}
        aria-labelledby={this.title}
        fullScreen
        classes={{
          root: css(Styles.root),
          paper: css(Styles.paper),
        }}
      >
        <div className={css(Styles.modalContainer)}>
          <div className={css(Styles.modalHeader)}>
            <div className={css(Styles.imageContainer)}>
              <div className={css(Styles.imageInnerContainer)}>
                <img
                  src={AppConfig.LOGO}
                  alt='Logo' className={css(Styles.image)} />
              </div>
            </div>
            <div className={css(Styles.modalTitle)}>
              <p className={css(Styles.modalTitleContent)}>{this.title}</p>
            </div>
            <div className={css(Styles.modalButtonContainer)}>
              {window.innerWidth > 768 &&
                <Fragment>
                  <p>{Helper.getString('desktopView')}</p>
                  <AppThemeSwitch
                    checked={this.state.mobileView}
                    onChange={(e) => this.handleChange(e)}
                  />
                  <p>{Helper.getString('mobileView')}</p>
                </Fragment>
              }

              <div className={css(Styles.iconContainer)}  >
                <Tooltip title={'Refresh'} classes={{ popper: css(Styles.tooltip) }} arrow>
                  <IconButton onClick={() => this.iframeRefresh()}>
                    <i className='material-icons-outlined' style={{ fontSize: 20, color: AppTheme.primaryColor }}>refresh</i>
                  </IconButton>
                </Tooltip>
              </div>

              {/* {this.props.orgId && */}
              <button className={css(Styles.modalCloseButton)} onClick={this.onCloseModal.bind(this)} disabled={this.state.inProcess}>
                <svg className={css(Styles.modalCloseIcon)} viewBox='0 0 36 36'>
                  <path d='M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z'></path>
                </svg>
              </button>
              {/* } */}
            </div>
          </div>
        </div>

        {
          this.state.mobileView ?
            <div className={css(Styles.modalMobileOuterContent)}>
              <div class='device device-iphone-x'>
                <div class='device-frame'>
                  {this.state.iframeUrl &&
                    <iframe key={this.state.iframeKey} src={this.state.iframeUrl} className={css(Styles.iFrameStyleMobile)} />
                  }
                  {this.state.template.htmlContent &&
                    <iframe key={this.state.templateId} srcDoc={this.state.template.htmlContent} className={css(Styles.iFrameStyleMobile)} />
                  }
                </div>
                <div class='device-stripe'></div>
                {/* <div class='device-header'></div>
                <div class='device-sensors'></div> */}
                <div class='device-btns'></div>
                <div class='device-power'></div>
                <div class='device-home'></div>
              </div>
            </div>
            :
            <div className={css(Styles.modalDesktopContent)}>
              <div class='device device-macbook-pro device-spacegray'>
                <div class='device-frame'>
                  {this.state.iframeUrl &&
                    <iframe key={this.state.iframeKey} src={this.state.iframeUrl} className={css(Styles.iFrameStyle)} />
                  }
                  {this.state.template.htmlContent &&
                    <iframe key={this.state.templateId} srcDoc={this.state.template.htmlContent} className={css(Styles.iFrameStyle)} />
                  }
                </div>
                <div class='device-stripe'></div>
                <div class='device-header'></div>
                <div class='device-sensors'></div>
                <div class='device-btns'></div>
                <div class='device-power'></div>
              </div>
            </div>
        }
      </Dialog >
    );
  }
}

const Styles = AppConfig.CUSTOM_STYLE ? CustomPreviewDialogStyles : StyleSheet.create({
  modalHeader: {
    backgroundColor: 'whitesmoke',
    padding: 10
  },
  modalTitle: {
    color: 'black',
    fontSize: 20,
  },
  modalContent: {
    maxWidth: 400,
    marginTop: 20,
    marginBottom: 20,
    padding: 10
  },
  modalCloseButton: {
    position: 'absolute',
    top: 8,
    right: 5,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    outline: 'none',
    cursor: 'pointer'
  },
  modalCloseIcon: {
    width: 28,
    height: 28
  }
})
