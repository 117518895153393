import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Divider, Tooltip } from '@material-ui/core';
import { AppStyles } from '../../styles/AppTheme';
import { AppTheme } from '../../styles/AppTheme';
import { Link } from 'react-router-dom';

export class MenuItemsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    let actions = []
    actions = Object.assign([], this.props.onMenuItems(this.props.current))

    return (
      actions.length > 0 &&
      <div>
        <Tooltip title='Actions' placement='top-start' key={this.props.current.id}>
          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup='true'
            onClick={this.handleMenu}
            color={this.props.public ? 'primary' : 'primary'}
            className={this.props.public ? css(Styles.icon) : css(Styles.icon)}
          >
            <i className='material-icons-outlined' style={{ fontSize: 25, zIndex: 100 }}>more_vert</i>
          </IconButton>
        </Tooltip>
        <Popover
          id='menu-appbar'
          anchorEl={anchorEl}
          onClick={() => this.setState({ anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
          classes={{
            paper: css([Styles.popover, this.props.customPopover])
          }}
        >

          {actions.map((items,index) => {
            if(items.to) {
              return(
                <div>
                  <MenuItem className={css(Styles.menuItem)}>
                    <Link to={items.to} key={index} className={css(Styles.link)}>
                      <span className={css(Styles.entityTableLinkIcon, Styles.entityTableLinkIconMenu)}>
                        <i className='material-icons-outlined'>{items.icon}</i>
                      </span>
                      <p className={css(Styles.titleContainer)}>{items.title}</p>
                    </Link>
                  </MenuItem>
                </div>
              )
            } else {
              return (
                Object.assign([], items)
              )
            }
          })
          }

        </Popover>
      </div>
    )
  }
}

const Styles = StyleSheet.create({
  titleContainer: {
    marginLeft: 5,
    fontSize: 14,
    color: '#6E84A3',
  },
  menuItem: {
    color: '#6E84A3',
    padding: '0px 5px',
    opacity: 1,
    fontWeight: 400,
    ':hover': {
      color: 'black',
      backgroundColor: 'white'
    }
  },
  entityTableLinkIcon: {
    padding: 3,
    fontSize: 18,
    color: AppTheme.primaryColor,
    cursor: 'pointer'
  },
  entityTableLinkIconMenu: {
    height: 26,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5
  },
  icon: {
    padding: 0
  },
  popover: {
    display:'flex',
    flexDirection:'column',
    padding: '10px',
    boxShadow: '3px 6px 43px rgba(0, 0, 0, 0.05)',
    border: '1px solid ' + AppTheme.borderColor,
    marginTop: 10,
    minWidth: 100,
    borderRadius: 16,
    gap: 8
  },
  divider: {
    color: '#edf2f9',
    margin: '3px 0px'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
})
